import React, { useState } from "react";
import ModalPopup from "../ModalPopup/ModalPopup";
import Spinner from "../../Shared/Spinner/Spinner";
import { Form } from "antd";
import { DatePicker, Select, Spin, message } from "antd";
import moment from "moment";
import { AUTOSUGGEST_QUERY } from "../../Apollo/Queries";
import { ASSIGN_KEYS_MUTATION } from "../../Apollo/Mutations";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import { formItemLayout } from "../../Shared/Helpers";
import API from "../../services/API";

const initialState = {
  userName: undefined,
  userNt: undefined,
  computer: null,
  startDate: moment(),
  endDate: null,
};

const AssignKeys = (props) => {
  const [visible, setVisible] = useState(false);
  const [state, setState] = useState({ ...initialState });
  const [loading, setLoading] = useState(false);
  const showModal = () => {
    setVisible(true);
  };

  const onChangeNt = (e, f) => {
    setState({
      ...state,
      userNt: e,
      userName: f ? f.props.children.split(";")[0] : null,
    });
  };

  const onChangeStart = (a, b) => {
    setState({ ...state, startDate: b });
  };

  const onChangeEnd = (a, b) => {
    setState({ ...state, endDate: b });
  };
  const handleCancel = (e) => {
    setVisible(false);
    setState({ ...initialState });
  };

  const onSubmit = async () => {
    const { paging, KeyDataId } = props;
    if (state.userNt && state.startDate && paging) {
      setLoading(true);
      const result = await API.post(`KeyUse`, null, {
        headers: {
          KeyDataId: KeyDataId,
          UserNt: state.userNt,
          UserName: state.userName,
        },
      })
        .then(({ data }) => {
          setLoading(false);
          setVisible(false);
        })
        .catch(function (error) {
          message.error("Check for License or Available Keys");
          console.log(JSON.stringify(error));
          setLoading(false);
          handleCancel();
        });
    } else {
      message.error("Please fill out all required fields");
    }
  };

  const onSearchChange = (e) => {
    if (e) {
      setTimeout(() => {
        props.data.refetch({ nt: e });
      }, 500);
    }
  };

  const dataHelper = () => {
    //TODO: Azure Migration IN PROGRESS - Fix Autocomplete Select User not sending ntLogon

    return props.data.autoSuggestByNt
      ? props.data.autoSuggestByNt.map((a) => {
          return (
            <Select.Option key={a.ntLogon}>
              {`${a.name}; ${a.title}`}
            </Select.Option>
          );
        })
      : null;
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf("day");
  };

  return (
    <div style={{ marginBottom: 15 }}>
      <ModalPopup
        type="outlined"
        header="Assign Key"
        buttonText="Assign new user"
        name="Submit"
        icon="user-add"
        disable={props.create ? false : true}
        notFoundContent={loading ? <Spin size="small" /> : null}
        showModal={showModal}
        handleCancel={handleCancel}
        visible={visible}
        submit={onSubmit}
      >
        <Spinner loading={loading}>
          <Form>
            <Form.Item label="Username" required {...formItemLayout}>
              <Select
                autoFocus
                showSearch
                placeholder="Lastname, Firstname or NT"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
                value={state.userNt}
                onChange={onChangeNt}
                onSearch={onSearchChange}
                allowClear
              >
                {dataHelper()}
              </Select>
            </Form.Item>
            <Form.Item label="Assigned date" required {...formItemLayout}>
              <DatePicker
                allowClear={false}
                disabledDate={disabledDate}
                value={moment(state.startDate, "MM/DD/YYYY")}
                onChange={onChangeStart}
                format={"MM/DD/YYYY"}
              />
            </Form.Item>
            <Form.Item label="End Date" {...formItemLayout}>
              <DatePicker
                value={
                  state.endDate ? moment(state.endDate, "MM/DD/YYYY") : null
                }
                disabledDate={disabledDate}
                onChange={onChangeEnd}
                format={"MM/DD/YYYY"}
              />
            </Form.Item>
          </Form>
        </Spinner>
      </ModalPopup>
    </div>
  );
};

const AssignKey = compose(
  graphql(AUTOSUGGEST_QUERY),
  graphql(ASSIGN_KEYS_MUTATION)
)(AssignKeys);

export default AssignKey;
